.dashboard::-webkit-scrollbar{
    display: none;
}
.roomNumber {
   width: 180px;
   padding: 0.2rem;
   background: #F9F9FB;
   border-radius: 7px
}

.roomTotalHeader {
   font-family: 'Manrope';
   font-style: normal;
   font-weight: 600;
   font-size: 12.9008px;
   line-height: 19px;
   color: #000000;
}

.roomTotalNumber {
   font-family: 'Manrope';
   font-style: normal;
   font-weight: 800;
   font-size: 12.9008px;
   line-height: 19px;
   color: #000000;
   margin-left: 30px;

}
.scrollBarRemove::-webkit-scrollbar {
   display: none;
 }
 @media screen and (max-width: 460px) {
   .width{
      width: 100vw;
      height: 80vh;
    margin-top: 10vh;
    overflow: scroll;
    background-color: rgb(249, 249, 251);
   }
   .topSelector{
      /* padding-top: 12vh; */
      padding-left: 7vw;
      background-color: #FFFFFF; 

   }
 }
 @media screen and (min-width: 760px) {
   .width{
      width: 95vw;
      height: 90vh;
   }
   .topSelector{
      padding-left: 30px;
      padding-top: 30px; 
      background-color: #FFFFFF; 
   }
 }