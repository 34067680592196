.tableHeading {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 12.9008px;
    line-height: 19px;
    color: #9D9D9D !important;
}

.tableData {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 12.9008px;
    line-height: 19px;
    color: #000000;
}
@media screen and (max-width: 460px) {
    .tableStyle{
        width: 96% !important;
        margin: auto;
        border-radius: 12px; 
        overflow: hidden;
    }
}
@media screen and (min-width: 760px) {
 .tableStyle{
    text-align: left;
    margin-left: 30px; 
    width: 96%; 
    border-radius: 12px; 
    overflow: hidden;
 }

}



