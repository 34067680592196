@media screen and (max-width: 560px) {
    .innerContainer{
        width:80%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding:2.5%;
        border-radius:12px;
    }
}

@media screen and (min-width: 760px) {
    .innerContainer{
        width:25%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding:2.5%;
        border-radius:12px;
    }
}