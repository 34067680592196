@media screen and (max-width: 460px) {
.siderBarContaoner{
    width: 64%;
    background-color: rgb(255, 255, 255);
    display: flex;
    position: fixed;
    top: 10vh;
    z-index: 5;
    justify-content: space-around;
}
}

@media screen and (min-width: 760px) {
    .siderBarContaoner{
        width:5%;
        height:100vh;
        background-color:#FFFFFF;
    }
}